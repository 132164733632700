import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';



import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  useEffect(() => {
    fetch('/api/location')
      .then((response) => response.json())
      .then((data) => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase()));
        setCity(decodedCity);

        // Combine the city, region, and country for the geocoding API
        const query = `${decodedCity}, ${regionNames.of(data.country.toUpperCase())}, ${data.country}`;

        // Call the PositionStack geocoding API
        const apiKey = '2e018d9e84f864356a09b12a3cba11c2';
        const apiUrl = `https://api.positionstack.com/v1/forward?access_key=fcf238927a0f9117103dc3ec7073b630&query=${encodeURIComponent(query)}`;

        axios
          .get(apiUrl)
          .then((response) => {
            if (response.data && response.data.data && response.data.data.length > 0) {
              const geocodeResult = response.data.data[0];
              // You can set or use the geocodeResult as needed
              console.log('Geocode result:', geocodeResult);
              setRegion(decodeURIComponent(geocodeResult.region.replace(/\+/g, ' ')));
            } else {
              console.warn('No geocode data found.');
            }
          })
          .catch((error) => {
            console.error('Geocoding API error:', error);
          })
          .finally(() => {
            setFetchAttempted(true);
          });
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setFetchAttempted(true); // Ensure fetchAttempted is set even if there's an error
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  // Conditionally render content only after the geolocation fetch is complete
  return fetchAttempted ? (

              <div className="container">
                <a className="header-logo" href="https://onlyfans.com/alixberklyxo/c1">
                  <div className="onlyfans"></div>
                </a>
                <img
                  src="https://i.ibb.co/t447qsL/photo-2024-05-03-03-34-33-modified.png"
                  alt="Header Image"
                  className="header-image"
                />

                <div className="textContainer">
                  <section>
                    <div className="username">
                      <strong>Alix Berkly❤️</strong>
                    </div>


                    <div id="user-status" className="user-status">
                      <div className="status"></div>
                      <div id="available">Available now</div>
                      <div className="separator"></div>
                      <div className="clock"></div>
                      <div className="respond-time">Responds in 2 minutes</div>
                    </div>
                  </section>
                </div>

                <div className="textContainer">
                  <div className="location"></div>
                  <span id="location-name">{region ? `${region}` : ''}</span>
                </div>

                <div className="textContainer2">
                  <div className="time-in-current-location">
                    Wanna hang in {region || 'your city'}?
                  </div>
                </div>



                <div className="textContainer3">
                  <a href="https://onlyfans.com/alixberklyxo/c1" id="customButton">
                    <div className="onlyfans"></div>
                    Free Onlyfans page ❤️
                  </a>
                </div>




                <Analytics />
              </div>

      ) : (
        <div></div> 
  );
}

export default App;
